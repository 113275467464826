<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <base-crud
          :ref="'processCrud'"
          crudName="Processos"
          newText="Novo"
          :table="table"
          :columns="columns"
          :options="options"
          :endPoint="endPoint"
          :enableAdd="true"
          :enableEdit="true"
          :enableDelete="true"
          :enableView="true"
          :enableReset="false"
          :dataForm="dataForm"
        >
        </base-crud>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
  data: function () {
    return {
      table: "usersTable",
      showMessageImport: false,
      processCrud: {},
      pages: [],
      url: "",
      columns: ["id", "number", "clippings", 'responsible_id', 'status', "actions"],
      tableData: ["id", "number", "clippings", 'responsible_id', 'status'],
      options: {
        filterByColumn: true,
        debounce: 1000,
        filterable: ["number", "clippings"],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          id: "ID",
          number: "Número",
          clippings: "Recortes",
          responsible_id: "Responsável",
          actions: "Ações",
        },

        texts: {
          filterBy: "Filtrar",
          defaultOption: "Selecione",
        },        
        templates: {
          status: function (h, row, index) {
            return row.status == 1
              ? 'Ativo'
              : 'Não Ativo';
          },
          responsible_id: function (h, row, index) {
            return row.responsible ? row.responsible.name
              : 'Não atribuído';
          },
          clippings: function (h, row, index) {
            if(row.pending_clippings == true || row.pending_clippings == 'true'){
              return (
                <h5 class="text-center">
                  {row.clippings.length} <span class="ml-2 badge badge-warning">
                    Pendências
                  </span>
                </h5>
              );
            } else {
              return  (
                <h5 class="text-center"> { row.clippings.length } 
                  <span class="ml-2 badge badge-success">
                    OK
                  </span>
                </h5>
              );
            }
          },
        },
        requestFunction: function (data) {
          let requestData = {};

          console.log(this.$parent.$parent);

          let query = this.$parent.$parent.$parent.query(data.query);
          requestData.params = data;
          requestData.params.query = "";
          requestData.params.with = ['responsible', 'clippings'];

          return axios.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      endPoint: "processes",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
  },
  methods: {
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      let fileImport = document.getElementById("fileImport");

      fd.append("fileImport", fileImport.files[0] ? fileImport.files[0] : "");

      return fd;
    },

    query: function (query) {
      let columns = {
        id: "id",
        number: "number",
        responsible_id: "responsible[responsible_id]"
      };
      let filters = "";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}
.uploading-image {
  display: flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}
</style>